<template>
  <div class="form-horizontal">
    <div v-if="run.grid">
      <h4>Electric Grid Climate Emissions Factors <tooltip :association_1="'site'" :association_2="'emissions'" :attribute="'climate_section_header'"></tooltip></h4>
      <!-- <p>How would you like to enter the emissions factors?</p> -->
      <div
        v-if="displayGridClimateEmissionsWarning"
        class="alert alert-danger alert-form-errors">
        <p>Default grid climate emissions factors cannot be determined for this location and will be assumed to be zero unless modified.</p>
      </div>

      <input v-model="run.site.electric_tariff.cambium_emissions_type" class="form-control hidden" value="hourly" type="hidden" name="run[site_attributes][electric_tariff_attributes][cambium_emissions_type]" />

      <ul class="nav nav-tabs">
        <li><a @click="updateClimateEmissionsType('hourly'); toggleEmissionsYears();" data-toggle="tab" href="#cambium_emissions_factor_hourly"><i class="fas fa-hourglass" aria-hidden="true"></i> Hourly</a></li>
        <li><a @click="updateClimateEmissionsType('annual');" data-toggle="tab" href="#cambium_emissions_factor_annual"><i class="fas fa-calendar-alt" aria-hidden="true"></i> Annual</a></li>
        <li><a @click="updateClimateEmissionsType('uploaded');" data-toggle="tab" href="#cambium_emissions_factor_upload"><i class="fas fa-upload" aria-hidden="true"></i> Upload</a></li>
      </ul>

      <div class="tab-content">
        <div>
          <div v-if="run.site.electric_tariff.cambium_emissions_type == 'hourly'" class="tab-pane" id="cambium_emissions_factor_hourly">
            <div class="col-sm-8 col-sm-offset-2" style="margin-bottom: 15px;">Inputs to generate levelized climate emissions factors from <a href="https://scenarioviewer.nrel.gov/" target="_blank">Cambium</a> data</div>
            <br />
            <horizontal-select-input-nested-2
              association_1='site'
              association_2='electric_tariff'
              attribute='cambium_location_type' />
            <input
              v-model="run.site.electric_tariff.cambium_levelization_years"
              class="form-control hidden"
              type="hidden"
              name="run[site_attributes][electric_tariff_attributes][cambium_levelization_years]"
              id="run_site_attributes_electric_tariff_attributes_cambium_levelization_years" />
            <horizontal-select-input-nested-2
              association_1='site'
              association_2='electric_tariff'
              attribute='cambium_metric_col' />
            <horizontal-select-input-nested-2
              association_1='site'
              association_2='electric_tariff'
              attribute='cambium_scenario' />
            <horizontal-select-input-nested-2
              association_1='site'
              association_2='electric_tariff'
              attribute='cambium_greening_of_grid'
              v-on:input-change="toggleEmissionsYears()" />
            <horizontal-string-input-nested-2
              association_1='site'
              association_2='electric_tariff'
              attribute='cambium_start_year' />
            <horizontal-select-input-nested-2
              association_1='site'
              association_2='electric_tariff'
              attribute='cambium_grid_level' />
          </div>
        </div>
        <div>
          <div v-if="run.site.electric_tariff.cambium_emissions_type == 'annual'" class="tab-pane" id="cambium_emissions_factor_annual">
            <horizontal-string-input-nested-2
              v-if="displayAnnualEmissionsFactors && run.site.address && !(run.site.address.includes('HI') || run.site.address.includes('Hawaii, USA') || run.site.address.includes('AK') || run.site.address.includes('Alaska, USA'))"
              association_1='site'
              association_2='electric_tariff'
              attribute='co2_annual_emissions_factor'
              required='true' />
            <horizontal-string-input-nested-2
              v-else-if="displayAnnualEmissionsFactors"
              association_1='site'
              association_2='electric_tariff'
              attribute='co2_annual_emissions_factor' />
            <horizontal-string-input-nested-2
              association_1='site'
              association_2='electric_tariff'
              attribute='emissions_factor_CO2_decrease_fraction' />
          </div>
        </div>
        <div>
          <div v-if="run.site.electric_tariff.cambium_emissions_type == 'uploaded'" class="tab-pane" id="cambium_emissions_factor_upload">
            <div class="form-group file required run_site_electric_tariff_cambium_emissions_file">
              <div class="col-sm-6 control-label">
                <label class="file required" for="run_site_attributes_electric_tariff_attributes_cambium_emissions_file">
                  <abbr title="Required" class="required">*</abbr> Hourly climate emissions factors (lb/kWh)
                  <tooltip
                    :association_1="'site'"
                    :association_2="'electric_tariff'"
                    :attribute="'cambium_emissions_file'" />
                </label>
              </div>
              <div class="col-sm-4">
                <input
                  class="form-control hidden"
                  type="hidden"
                  name="run[site_attributes][electric_tariff_attributes][cambium_emissions_file]"
                  id="run_site_attributes_electric_tariff_attributes_cambium_emissions_file" />
                <input
                  @change="parseCambiumEmissionsCSV($event)"
                  class="form-control-file file required load-profile-file-upload"
                  required="required"
                  aria-required="true"
                  type="file"
                  name="run[site_attributes][electric_tariff_attributes][cambium_emissions_file]" />
                <input
                  v-model="run.site.electric_tariff.uploaded_cambium_emissions_factor_profile"
                  class="form-control hidden"
                  type="hidden"
                  name="run[site_attributes][electric_tariff_attributes][uploaded_cambium_emissions_factor_profile]" />
                <!-- <p v-if="run.copied && this.run.site.electric_tariff.cambium_emissions_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.electric_tariff.cambium_emissions_file_data).metadata.filename }}</p> -->
                <p v-if="run.copied && this.run.site.electric_tariff.cambium_emissions_file_name" class="help-block">Uploaded file: {{ this.run.site.electric_tariff.cambium_emissions_file_name }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-offset-6 col-sm-6">
                <a style="font-size:12px;" href="/tool/grid_climate_emissions_factor_profile_template.csv">Sample custom climate emissions profile</a>
              </div>
            </div>
            <br>
            <horizontal-string-input-nested-2
              association_1='site'
              association_2='electric_tariff'
              attribute='emissions_factor_CO2_decrease_fraction' />
          </div>
        </div>
      </div>

      <!-- removing the download and view chart links for now until Cambium API can handle multile calls for each time an input is changed. -->
      <!-- <div v-if="run.site.electric_tariff.cambium_emissions_type == 'hourly'" class="row">
        <div class="col-sm-6">
          <a v-if="!emissionsHourlyLoading" @click="downloadEmissionsProfile('emissionsHourlyProfile')" class="load-profile-download-link load-profile-action no-visit-color" data-load-type="emissions">
            <i class="fas fa-download" aria-hidden="true"></i>Download emissions factor data
          </a>
          <div v-else class="emissions-download-spinner" data-load-type="emissions">
            <i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i>
          </div>
        </div>
        <div class="col-sm-6 text-right">
          <a v-if="!emissionsHourlyLoading" @click="displayEmissionsHourlyProfile()" class="load-profile-chart-link load-profile-action no-visit-color" data-load-type="emissions">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart emissions factor data
          </a>
          <div v-else class="emissions-chart-spinner" data-load-type="emissions">
            <i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div v-else-if="run.site.electric_tariff.cambium_emissions_type == 'uploaded'" class="row" style="padding-right:15px;">
        <div class="col-sm-offset-6 text-right">
          <a v-if="!emissionsUploadedLoading" @click="displayEmissionsUploadedProfile()" class="load-profile-chart-link load-profile-action no-visit-color" data-load-type="emissions">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart emissions factor data
          </a>
          <div v-else class="emissions-chart-spinner" data-load-type="emissions">
            <i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i>
          </div>
        </div>
      </div> -->

      <!-- the below <transitions>'s are for the charts which have been temporarily removed. 
      Remove this comment once the view chart links above have been commented back in -->
      <transition name="fade">
        <emissions-profile-modal
          v-if="showEmissionsHourlyProfile"
          :type="'emissionsHourlyProfile'" />
      </transition>
      <transition name="fade">
        <emissions-profile-modal
          v-if="showEmissionsUploadedProfile"
          :type="'emissionsUploadedProfile'" />
      </transition>

      <br />
      <h4>Electric Grid Health Emissions Factors <tooltip :association_1="'site'" :association_2="'emissions'" :attribute="'health_section_header'"></tooltip></h4>
      <div
        v-if="displayGridHealthEmissionsWarning"
        class="alert alert-danger alert-form-errors">
        <p>Default grid health emissions factors cannot be determined for this location and will be assumed to be zero unless modified.</p>
      </div>

      <input v-model="run.site.electric_tariff.emissions_type" class="form-control hidden" value="hourly" type="hidden" name="run[site_attributes][electric_tariff_attributes][emissions_type]" />

      <ul class="nav nav-tabs">
        <li><a @click="updateEmissionsType('hourly');" data-toggle="tab" href="#emissions_factor_hourly"><i class="fas fa-hourglass" aria-hidden="true"></i> Hourly</a></li>
        <li><a @click="updateEmissionsType('annual');" data-toggle="tab" href="#emissions_factor_annual"><i class="fas fa-calendar-alt" aria-hidden="true"></i> Annual</a></li>
        <li><a @click="updateEmissionsType('uploaded');" data-toggle="tab" href="#emissions_factor_upload"><i class="fas fa-upload" aria-hidden="true"></i> Upload</a></li>
      </ul>

      <div class="tab-content">
        <div>
          <div v-if="run.site.electric_tariff.emissions_type == 'hourly'" class="tab-pane" id="emissions_factor_hourly">
            <div class="col-sm-8 col-sm-offset-2" style="margin-bottom: 15px;">Inputs to generate levelized health emissions factors from <a href="https://www.epa.gov/avert" target="_blank">AVERT</a> data</div>
            <!-- commenting out the updateEmissionsHourlyProfile api call when avert region changed, for now.
            This is only called when the view chart link is visible and therefore needs to update the information displayed.
            Un-comment when view chart option is functional again. -->
            <!-- <horizontal-select-input-nested-2
              association_1='site'
              association_2='electric_tariff'
              attribute='emissions_factor_hourly_source'
              @input-change="updateEmissionsHourlyProfile()" /> -->
            <horizontal-select-input-nested-2
              association_1='site'
              association_2='electric_tariff'
              attribute='emissions_factor_hourly_source' />
            <horizontal-string-input-nested-2
              association_1='site'
              association_2='electric_tariff'
              attribute='emissions_factor_nox_so2_pm25_decrease_fraction' />
          </div>
        </div>
        <div>
          <div v-if="run.site.electric_tariff.emissions_type == 'annual'" class="tab-pane" id="emissions_factor_annual">
            <horizontal-string-input-nested-2
              v-if="displayAnnualEmissionsFactors && run.site.address && !(run.site.address.includes('HI') || run.site.address.includes('Hawaii, USA') || run.site.address.includes('AK') || run.site.address.includes('Alaska, USA'))"
              association_1='site'
              association_2='electric_tariff'
              attribute='nox_annual_emissions_factor'
              required='true' />
            <horizontal-string-input-nested-2
              v-else-if="displayAnnualEmissionsFactors"
              association_1='site'
              association_2='electric_tariff'
              attribute='nox_annual_emissions_factor' />
            <horizontal-string-input-nested-2
              v-if="displayAnnualEmissionsFactors && run.site.address && !(run.site.address.includes('HI') || run.site.address.includes('Hawaii, USA') || run.site.address.includes('AK') || run.site.address.includes('Alaska, USA'))"
              association_1='site'
              association_2='electric_tariff'
              attribute='so2_annual_emissions_factor'
              required='true' />
            <horizontal-string-input-nested-2
              v-else-if="displayAnnualEmissionsFactors"
              association_1='site'
              association_2='electric_tariff'
              attribute='so2_annual_emissions_factor' />
            <horizontal-string-input-nested-2
              v-if="displayAnnualEmissionsFactors && run.site.address && !(run.site.address.includes('HI') || run.site.address.includes('Hawaii, USA') || run.site.address.includes('AK') || run.site.address.includes('Alaska, USA'))"
              association_1='site'
              association_2='electric_tariff'
              attribute='pm25_annual_emissions_factor'
              required='true' />
            <horizontal-string-input-nested-2
              v-else-if="displayAnnualEmissionsFactors"
              association_1='site'
              association_2='electric_tariff'
              attribute='pm25_annual_emissions_factor' />
            <horizontal-string-input-nested-2
              association_1='site'
              association_2='electric_tariff'
              attribute='emissions_factor_nox_so2_pm25_decrease_fraction' />
          </div>
        </div>
        <div>
          <div v-if="run.site.electric_tariff.emissions_type == 'uploaded'" class="tab-pane" id="emissions_factor_upload">
            <div class="form-group file required run_site_electric_tariff_emissions_file">
              <div class="col-sm-6 control-label">
                <label class="file required" for="run_site_attributes_electric_tariff_attributes_emissions_file">
                  <abbr title="Required" class="required">*</abbr> Hourly health emissions factors (lb/kWh)​
                  <tooltip
                    :association_1="'site'"
                    :association_2="'electric_tariff'"
                    :attribute="'emissions_file'" />
                </label>
              </div>
              <div class="col-sm-4">
                <input
                  class="form-control hidden"
                  type="hidden"
                  name="run[site_attributes][electric_tariff_attributes][emissions_file]"
                  id="run_site_attributes_electric_tariff_attributes_emissions_file" />
                <input
                  @change="parseHealthEmissionsCSV($event)"
                  class="form-control-file file required load-profile-file-upload"
                  required="required"
                  aria-required="true"
                  type="file"
                  name="run[site_attributes][electric_tariff_attributes][emissions_file]" />
                <input
                  v-model="run.site.electric_tariff.uploaded_emissions_factor_profile"
                  class="form-control hidden"
                  type="hidden"
                  name="run[site_attributes][electric_tariff_attributes][uploaded_emissions_factor_profile]" />
                <!-- <p v-if="run.copied && this.run.site.electric_tariff.emissions_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.electric_tariff.emissions_file_data).metadata.filename }}</p> -->
                <p v-if="run.copied && this.run.site.electric_tariff.emissions_file_name" class="help-block">Uploaded file: {{ this.run.site.electric_tariff.emissions_file_name }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-offset-6 col-sm-6">
                <a style="font-size:12px;" href="/tool/grid_emissions_factor_profile_template.csv">Sample custom health emissions profile</a>
              </div>
            </div>
            <br>
            <horizontal-string-input-nested-2
              association_1='site'
              association_2='electric_tariff'
              attribute='emissions_factor_nox_so2_pm25_decrease_fraction' />
          </div>
        </div>
      </div>

      <!-- removing the download and view chart links for now until Cambium API can handle multile calls for each time an input is changed. -->
      <!-- <div v-if="run.site.electric_tariff.emissions_type == 'hourly'" class="row">
        <div class="col-sm-6">
          <a v-if="!emissionsHourlyLoading" @click="downloadEmissionsProfile('emissionsHourlyProfile')" class="load-profile-download-link load-profile-action no-visit-color" data-load-type="emissions">
            <i class="fas fa-download" aria-hidden="true"></i>Download emissions factor data
          </a>
          <div v-else class="emissions-download-spinner" data-load-type="emissions">
            <i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i>
          </div>
        </div>
        <div class="col-sm-6 text-right">
          <a v-if="!emissionsHourlyLoading" @click="displayEmissionsHourlyProfile()" class="load-profile-chart-link load-profile-action no-visit-color" data-load-type="emissions">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart emissions factor data
          </a>
          <div v-else class="emissions-chart-spinner" data-load-type="emissions">
            <i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div v-else-if="run.site.electric_tariff.emissions_type == 'uploaded'" class="row" style="padding-right:15px;">
        <div class="col-sm-offset-6 text-right">
          <a v-if="!emissionsUploadedLoading" @click="displayEmissionsUploadedProfile()" class="load-profile-chart-link load-profile-action no-visit-color" data-load-type="emissions">
            <i class="fas fa-chart-area" aria-hidden="true"></i>Chart emissions factor data
          </a>
          <div v-else class="emissions-chart-spinner" data-load-type="emissions">
            <i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i>
          </div>
        </div>
      </div> -->

      <!-- the below <transition>'s are for the charts which have been temporarily removed. 
      Remove this comment once the view chart links above have been commented back in -->
      <transition name="fade">
        <emissions-profile-modal
          v-if="showEmissionsHourlyProfile"
          :type="'emissionsHourlyProfile'" />
      </transition>
      <transition name="fade">
        <emissions-profile-modal
          v-if="showEmissionsUploadedProfile"
          :type="'emissionsUploadedProfile'" />
      </transition>
      <br />
    </div>

    <div class="row section-actions">
      <div class="col-sm-6 col-sm-offset-6">
        <div class="toggle-more-inputs pull-left">
          <a @click="toggleAdvancedInputs();">
            <span v-if="displayAdvancedInputs">
              <font-awesome-icon icon='minus-square' />
              Show fewer inputs
            </span>
            <span v-else>
              <font-awesome-icon icon='plus-square' />
              Advanced inputs
            </span>
          </a>
        </div>
        <div v-if="!displayAdvancedInputs" class="reset-inputs pull-right">
          <a @click="resetInputs()">
            <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
          </a>
        </div>
      </div>
    </div>

    <div v-show="displayAdvancedInputs">
      <div v-if="run.grid">
        <h4>Treatment of exported electricity</h4>
        <horizontal-select-input-nested-1
          association_1='site'
          attribute='include_exported_renewable_electricity_in_total' />
        <horizontal-select-input-nested-1
          association_1='site'
          attribute='include_exported_elec_emissions_in_total' />
      </div>

      <div v-if="run.analyze_chp || run.analyze_generator || run.analyze_ghp || run.analyze_prime_generator || this.run.analyze_ashp_space">
        <br />
        <h4>Fuel Emissions Factors <tooltip :association_1="'site'" :association_2="'emissions'" :attribute="'fuel_factors'"></tooltip></h4>
        <emissions-table />
      </div>

      <br />
      <h4>Emissions Costs</h4>
      <p><i>Note: By default, emissions costs will NOT impact the optimal system size nor net present value. If you wish to consider emissions costs as true/incurred costs in the optimization, select “Clean Energy Goals” and select “Yes” to “Include emissions costs in the objective.”</i></p>

      <br />
      <h4>Climate Costs</h4>
      <div class="form-horizontal">
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='financial'
          attribute='CO2_cost_per_tonne' />
      </div>

      <br />
      <h4>Health Costs</h4>
      <div
        v-if="displayEmissionsHealthCostsWarning"
        class="alert alert-danger alert-form-errors">
        <p>Default health costs of emissions and cost escalation rates cannot be determined for this location.</p>
        <p>Health costs will be assumed to be zero unless custom costs are entered.</p>
      </div>
      <emissions-health-costs-table />

      <br />
      <h4>Climate & Health Cost Escalation Rates <tooltip :association_1="'site'" :association_2="'emissions'" :attribute="'cost_escalation_rates'"></tooltip></h4>
      <div class="form-horizontal">
        <cost-escalation-rates-table />
      </div>

      <div v-if="run.analyze_chp || run.analyze_ghp || run.analyze_prime_generator || run.analyze_generator || this.run.analyze_ashp_space">
        <br />
        <h4>Fuel Renewable Energy Content <tooltip :association_1="'site'" :association_2="'emissions'" :attribute="'fuel_RE_content'"></tooltip></h4>
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='boiler'
          attribute='fuel_percent_RE' />
        <horizontal-string-input-nested-2
          v-if="run.analyze_chp"
          association_1='site'
          association_2='chp'
          attribute='fuel_percent_RE' />
        <horizontal-string-input-nested-2
          v-if="run.analyze_prime_generator"
          association_1='site'
          association_2='prime_generator'
          attribute='fuel_percent_RE' />
        <horizontal-string-input-nested-2
          v-if="run.analyze_generator"
          association_1='site'
          association_2='generator'
          attribute='generator_fuel_percent_RE' />
      </div>
    </div>

    <div v-if="displayAdvancedInputs" class="reset-inputs pull-right">
      <a @click="resetInputs()">
        <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from './Tooltip';
  import HorizontalSelectInput from './inputs/HorizontalSelectInput';
  import HorizontalSelectInputNested1 from './inputs/HorizontalSelectInputNested1';
  import HorizontalSelectInputNested2 from './inputs/HorizontalSelectInputNested2';
  import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
  import HorizontalStringInputNested3 from './inputs/HorizontalStringInputNested3';
  import EmissionsProfileModal from './modals/EmissionsProfileModal';
  import EmissionsTable from './inputs/EmissionsTable'
  import EmissionsHealthCostsTable from './inputs/EmissionsHealthCostsTable';
  import CostEscalationRatesTable from './inputs/CostEscalationRatesTable';

  export default {
    components: {
      EmissionsHealthCostsTable,
      EmissionsProfileModal,
      EmissionsTable,
      CostEscalationRatesTable,
      HorizontalSelectInput,
      HorizontalSelectInputNested1,
      HorizontalSelectInputNested2,
      HorizontalStringInputNested2,
      HorizontalStringInputNested3,
      Tooltip
    },

    data: function() {
      return {
        displayAdvancedInputs: false,
        // based on user selected avert region we send lat long to APIs /emissions_profile
        // I believe these will need to be updated once the charts are visible again
        epaAvertRegionToLatLong: {
          "California": [ 37.833015, -121.337023 ],
          "Carolinas": [ 34.921127, -79.657304 ],
          "Central": [ 37.686872, -98.390650 ],
          "Florida": [ 28.213436, -81.794875 ],
          "Mid-Atlantic": [ 40.324619, -76.276960 ],
          "Midwest": [ 43.074489, -89.395662 ],
          "New England": [ 43.876662, -71.543713 ],
          "Northwest": [ 45.465081, -115.801448 ],
          "New York": [ 42.799388, -75.190199 ],
          "Rocky Mountains": [ 39.032944, -105.849817 ],
          "Southeast": [ 32.802090, -84.764428 ],
          "Southwest": [ 34.327773, -110.971386 ],
          "Tennessee": [ 35.948486, -86.707355 ],
          "Texas": [ 31.893223, -99.238516 ],
        }
      }
    },

    computed: {
      ...mapGetters([
        'displayAnnualEmissionsFactors',
        'displayGridClimateEmissionsWarning',
        'displayGridHealthEmissionsWarning',
        'displayEmissionsHealthCostsWarning',
        'emissionsHourlyLoading',
        'emissionsHourlyProfile',
        'showEmissionsHourlyProfile',
        'emissionsUploadedLoading',
        'emissionsUploadedProfile',
        'showEmissionsUploadedProfile',
        'run',
        'urlRoot',
        'placeholders'
      ])
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },
      updateStore: function(attr, value) {
        var payload = {
          association_1: 'site',
          association_2: 'electric_tariff',
          attribute: attr,
          value: value,
        }

        this.$store.commit('updateValueNested2', payload);
      },
      updateClimateEmissionsType: function(type) {
        this.updateStore('cambium_emissions_type', type);
      },
      updateEmissionsType: function(type) {
        this.updateStore('emissions_type', type);
      },
      toggleEmissionsYears: function() {
        if (this.run.site.electric_tariff.cambium_greening_of_grid && !this.run.site.financial.analysis_years) {
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_levelization_years', value: '' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '2024 - ' + (2024 + parseInt(this.placeholders.site.financial.analysis_years) - 1) });
          if (this.run.site.electric_tariff.cambium_start_year) {
            this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '' });
          }
        } else if (this.run.site.electric_tariff.cambium_greening_of_grid && this.run.site.financial.analysis_years) {
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_levelization_years', value: this.run.site.financial.analysis_years });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '2024 - ' + (2024 + parseInt(this.run.site.financial.analysis_years) - 1) });
          if (this.run.site.electric_tariff.cambium_start_year) {
            this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '' });
          }
        } else if (!this.run.site.electric_tariff.cambium_greening_of_grid) {
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_levelization_years', value: 1 });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: 2024 });
        }
      },
      displayEmissionsHourlyProfile: function() {
        if(this.emissionsHourlyProfile.co2) {
          this.$store.commit('updateObject', { object: 'showEmissionsHourlyProfile', value: true });
        } else {
          bootbox.alert('Emissions factors must be selected or uploaded first.');
        }
      },
      displayEmissionsUploadedProfile: function() {
        if(this.emissionsUploadedProfile.co2) {
          this.$store.commit('updateObject', { object: 'showEmissionsUploadedProfile', value: true });
        } else {
          bootbox.alert('Emissions factors must be selected or uploaded first.');
        }
      },
      // this function was used to update the charts. Will need to split out co2 from nox, so2, pm25 when this is functional again I believe.
      // do not need to call the api when a region is changed until chart/download functionality is back.
      updateEmissionsHourlyProfile: function() {
        this.$store.commit('updateObject', { object: 'emissionsHourlyLoading', value: true });
        var region = this.run.site.electric_tariff.emissions_factor_hourly_source;
        var self = this;

        if(region && this.run.site.latitude && this.run.site.longitude) {
          $.ajax({
            url: this.urlRoot + "/emissions-profile",
            data: {
              latitude: this.epaAvertRegionToLatLong[region][0],
              longitude: this.epaAvertRegionToLatLong[region][1],
              // when viewing the emissisons charts are enabled again, this year value will either be 
              // 2017 if a user doesn't upload a custom typical load profile, or whatever value the 
              // user enters when they upload a custom load profile.
              year: 2017
            },
            success: function handleResults(data) {
              var payload = {
                co2: { series: data.emissions_factor_series_lb_CO2_per_kwh },
                nox: { series: data.emissions_factor_series_lb_NOx_per_kwh },
                so2: { series: data.emissions_factor_series_lb_SO2_per_kwh },
                pm25: { series: data.emissions_factor_series_lb_PM25_per_kwh },
              }

              self.$store.commit('updateObject', { object: 'emissionsHourlyProfile', value: payload });
            }
          })
        } else {
          this.$store.commit('updateObject', { object: 'emissionsHourlyProfile', value: {} });
        }

        setTimeout(function() {
          self.$store.commit('updateObject', { object: 'emissionsHourlyLoading', value: false });
        }, 800);
      },
      // until the download functionality is working again this function is irrelevant
      // it will need to get updated though for health and climate once functioning again.
      downloadEmissionsProfile: function(emissionsProfile) {
        if(!this[emissionsProfile].co2) {
          bootbox.alert('Emissions factors must be selected or uploaded first.');

          return false;
        }

        // var rows = [ ['Hour', 'Emissions factor (lbs CO2/kWh)', 'Emissions factor (lbs NOx/kWh)', 'Emissions factor (lbs SO2/kWh)', 'Emissions factor (lbs PM2.5/kWh)'] ];
        var rows = [ ['Hour', 'Emissions factor (lbs NOx/kWh)', 'Emissions factor (lbs SO2/kWh)', 'Emissions factor (lbs PM2.5/kWh)'] ];
        // var co2Values = this[emissionsProfile].co2.series;
        var noxValues = this[emissionsProfile].nox.series;
        var so2Values = this[emissionsProfile].so2.series;
        var pm25Values = this[emissionsProfile].pm25.series;
        for(var i = 0, len = 8760; i < len; i++) {
          // rows.push([ i + 1, co2Values[i], noxValues[i], so2Values[i], pm25Values[i] ]);
          rows.push([ i + 1, noxValues[i], so2Values[i], pm25Values[i] ]);
        }

        var csv = Papa.unparse(rows);
        var blob = new Blob([csv], {
          type: 'text/csv;charset=utf-8',
        });
        saveAs(blob, 'REopt Emissions Profile.csv');
      },
      average: function(array) {
        var total = 0;
        for(var i = 0; i < array.length; i++) {
            total += array[i];
        }

        return total / array.length;
      },
      parseCambiumEmissionsCSV: function() {
        // this.$store.commit('updateObject', { object: 'emissionsUploadedLoading', value: true });
        var file = event.target.files[0];

        if(file && file.name.split(".")[1] != "csv") {
          bootbox.alert("Uploaded file " + file.name + " must be a .csv");
          this.run.site.load_profile.loads_kw = [];
          event.target.value = "";

          return false;
        }

        if(file) {
          var self = this;
          Papa.parse(file, {
            delimiter: ",",
            complete: function(results) {
              try {
                if(results.errors && results.errors.length > 0) {
                  this.run.site.load_profile.loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The emissions profile could not be parsed. File must be a CSV.<br><br>Error (row ' + results.errors[0].row + '): ' + results.errors[0].message);

                  return false;
                }

                var rows = results.data;
                var co2Values = new Array(0);

                for(var i = 0, len = rows.length; i < len; i++) {
                  if (rows[0].length !== 2) {
                    bootbox.alert('The emissions profile must contain 2 columns (in the order of hour and CO2 emissions factor) of only numeric values. Could not parse value at row ' + (i + 1) + '. Download the sample custom emissions profile (link below input) and alter the values as needed.');
                    return false;
                  } else {
                    var row = rows[i];

                    // Detect header columns.
                    if(i == 0) {
                      if(row[0] && typeof row[0] === 'string') {
                        var value = row[0].toLowerCase();

                        // Skip header columns.
                        if(value.indexOf('hour') !== -1) {
                          continue;
                        }
                      }
                    }

                    // Ensure the values are only numbers.
                    var co2Value = parseFloat(row[1]);
                    if(isNaN(co2Value)) {
                      bootbox.alert('The emissions profile must contain 2 columns (in the order of hour and CO2 emissions factor) of only numeric values. Could not parse value at row ' + (i + 1) + '. Download the sample custom emissions profile (link below input) and alter the values as needed.');

                      return false;
                    }

                    co2Values.push(co2Value);
                  }
                }

                // Validate the file contains the expected number of value rows.
                var valuesCount = co2Values.length;
                if(valuesCount !== 8760) {
                  self.run.site.load_profile.loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The emissions profile must contain 2 columns (in the order of hour and CO2 emissions factor) of 8,760 values (hourly data) of numeric values. Download the sample custom emissions profile (link below input) and alter the values as needed.');

                  return false;
                }

                var payload = {
                  co2: { series: co2Values },
                }

                self.$store.commit('updateObject', { object: 'cambiumEmissionsUploadedProfile', value: payload });
                self.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'uploaded_cambium_emissions_factor_profile', value: JSON.stringify(payload) });
                self.$store.commit('updateValueNested2', {association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_emissions_file_name', value: file.name});
              } finally {
              }
            }
          })
        }

        setTimeout(function() {
          self.$store.commit('updateObject', { object: 'emissionsUploadedLoading', value: false });
        }, 800);
      },
      parseHealthEmissionsCSV: function(event) {
        this.$store.commit('updateObject', { object: 'emissionsUploadedLoading', value: true });
        var file = event.target.files[0];

        if(file && file.name.split(".")[1] != "csv") {
          bootbox.alert("Uploaded file " + file.name + " must be a .csv");
          this.run.site.load_profile.loads_kw = [];
          event.target.value = "";

          return false;
        }

        if(file) {
          var self = this;
          Papa.parse(file, {
            delimiter: ",",
            complete: function(results) {
              try {
                if(results.errors && results.errors.length > 0) {
                  this.run.site.load_profile.loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The emissions profile could not be parsed. File must be a CSV.<br><br>Error (row ' + results.errors[0].row + '): ' + results.errors[0].message);

                  return false;
                }

                var rows = results.data;
                var noxValues = new Array(0);
                var so2Values = new Array(0);
                var pm25Values = new Array(0);

                for(var i = 0, len = rows.length; i < len; i++) {
                  var row = rows[i];

                  // Detect header columns.
                  if(i == 0) {
                    if(row[0] && typeof row[0] === 'string') {
                      var value = row[0].toLowerCase();

                      // Skip header columns.
                      if(value.indexOf('hour') !== -1) {
                        continue;
                      }
                    }
                  }

                  // Ensure the values are only numbers.
                  var noxValue = parseFloat(row[1]);
                  var so2Value = parseFloat(row[2]);
                  var pm25Value = parseFloat(row[3]);

                  if(isNaN(noxValue) || isNaN(so2Value) || isNaN(pm25Value)) {
                    bootbox.alert('The emissions profile must contain 4 columns (in the order of hour, NOx emissions factor, SO2 emissions factor, and PM2.5 emissions factor) of only numeric values. Could not parse value at row ' + (i + 1) + '. Download the sample custom emissions profile (link below input) and alter the values as needed.');

                    return false;
                  }

                  noxValues.push(noxValue);
                  so2Values.push(so2Value);
                  pm25Values.push(pm25Value);
                }

                // Validate the file contains the expected number of value rows.
                var valuesCount = noxValues.length;
                if(valuesCount !== 8760) {
                  self.run.site.load_profile.loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The emissions profile must contain 4 columns (in the order of hour, NOx emissions factor, SO2 emissions factor, and PM2.5 emissions factor) of 8,760 values (hourly data) of numeric values. Download the sample custom emissions profile (link below input) and alter the values as needed.');

                  return false;
                }

                var payload = {
                  nox: { series: noxValues },
                  so2: { series: so2Values },
                  pm25: { series: pm25Values },
                }

                self.$store.commit('updateObject', { object: 'emissionsUploadedProfile', value: payload });
                self.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'uploaded_emissions_factor_profile', value: JSON.stringify(payload) });
                self.$store.commit('updateValueNested2', {association_1: 'site', association_2: 'electric_tariff', attribute: 'emissions_file_name', value: file.name});
              } finally {
              }
            }
          })
        }

        setTimeout(function() {
          self.$store.commit('updateObject', { object: 'emissionsUploadedLoading', value: false });
        }, 800);
      },
      resetInputs: function() {
        if(confirm("Are you sure you want to reset the inputs in the Emissions section to their default values?")) {
          var self = this;
          if(this.run.site.electric_tariff.emissions_type != 'hourly') {
            this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'emissions_type', value: 'hourly' });
            document.querySelectorAll("[href='#emissions_factor_hourly']")[0].click();
          }
          if(this.run.site.electric_tariff.cambium_emissions_type != 'hourly') {
            this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_emissions_type', value: 'hourly' });
            document.querySelectorAll("[href='#cambium_emissions_factor_hourly']")[0].click();
          }
          if(this.run.site.electric_tariff.emissions_factor_hourly_source) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'emissions_factor_hourly_source', value: '' });
          if(this.run.site.electric_tariff.co2_annual_emissions_factor) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'co2_annual_emissions_factor', value: '' });
          if(this.run.site.electric_tariff.nox_annual_emissions_factor) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'nox_annual_emissions_factor', value: '' });
          if(this.run.site.electric_tariff.so2_annual_emissions_factor) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'so2_annual_emissions_factor', value: '' });
          if(this.run.site.electric_tariff.pm25_annual_emissions_factor) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'pm25_annual_emissions_factor', value: '' });
          if(this.run.site.electric_tariff.cambium_emissions_file_name) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_emissions_file_name', value: '' });
          if(this.run.site.electric_tariff.emissions_file_name) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'emissions_file_name', value: '' });

          if(this.run.site.electric_tariff.emissions_factor_CO2_decrease_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'emissions_factor_CO2_decrease_fraction', value: '' });
          if(this.run.site.electric_tariff.emissions_factor_nox_so2_pm25_decrease_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'emissions_factor_nox_so2_pm25_decrease_fraction', value: '' });
          if(this.run.site.electric_tariff.cambium_location_type) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_location_type', value: 'GEA Regions' });
          if((this.run.site.electric_tariff.cambium_start_year && !this.run.site.electric_tariff.cambium_greening_of_grid && !this.run.site.financial.analysis_years) || (!this.run.site.electric_tariff.cambium_start_year && !this.run.site.electric_tariff.cambium_greening_of_grid && !this.run.site.financial.analysis_years)) {
            this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '' });
            this.$store.commit({type: 'updatePlaceholder', association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '2024 - ' + (2024 + parseInt(this.placeholders.site.financial.analysis_years) - 1) });
            $('#run_site_attributes_electric_tariff_attributes_cambium_start_year')[0].disabled = true;
          } else if ((this.run.site.electric_tariff.cambium_start_year && !this.run.site.electric_tariff.cambium_greening_of_grid && this.run.site.financial.analysis_years) || (!this.run.site.electric_tariff.cambium_start_year && !this.run.site.electric_tariff.cambium_greening_of_grid && this.run.site.financial.analysis_years)) {
            this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '' });
            this.$store.commit({type: 'updatePlaceholder', association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '2024 - ' + (2024 + parseInt(this.run.site.financial.analysis_years) - 1) });
            $('#run_site_attributes_electric_tariff_attributes_cambium_start_year')[0].disabled = true;
          }
          if(this.run.site.electric_tariff.cambium_levelization_years) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_levelization_years', value: '' });
          if(this.run.site.electric_tariff.cambium_metric_col) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_metric_col', value: 'lrmer_co2e' });
          if(this.run.site.electric_tariff.cambium_scenario) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_scenario', value: 'Mid-case' });
          if(!this.run.site.electric_tariff.cambium_greening_of_grid) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_greening_of_grid', value: true });
          if(this.run.site.electric_tariff.cambium_grid_level) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_grid_level', value: 'enduse' });

          if(!this.run.site.include_exported_renewable_electricity_in_total) this.$store.commit({type: 'updateValueNested1', association_1: 'site', attribute: 'include_exported_renewable_electricity_in_total', value: true });
          if(!this.run.site.include_exported_elec_emissions_in_total) this.$store.commit({type: 'updateValueNested1', association_1: 'site', attribute: 'include_exported_elec_emissions_in_total', value: true });

          if(this.run.site.generator.emissions_factor_lb_CO2_per_gal) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'emissions_factor_lb_CO2_per_gal', value: '' });
          if(this.run.site.generator.emissions_factor_lb_NOx_per_gal) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'emissions_factor_lb_NOx_per_gal', value: '' });
          if(this.run.site.generator.emissions_factor_lb_SO2_per_gal) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'emissions_factor_lb_SO2_per_gal', value: '' });
          if(this.run.site.generator.emissions_factor_lb_PM_per_gal) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'generator', attribute: 'emissions_factor_lb_PM_per_gal', value: '' });
          if(this.run.site.chp.emissions_factor_lb_CO2_per_mmbtu) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_CO2_per_mmbtu', value: '' });
          if(this.run.site.chp.emissions_factor_lb_NOx_per_mmbtu) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_NOx_per_mmbtu', value: '' });
          if(this.run.site.chp.emissions_factor_lb_SO2_per_mmbtu) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_SO2_per_mmbtu', value: '' });
          if(this.run.site.chp.emissions_factor_lb_PM_per_mmbtu) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_PM_per_mmbtu', value: '' });
          if(this.run.site.chp.fuel_percent_RE) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'chp', attribute: 'fuel_percent_RE', value: '' });
          if(this.run.site.boiler.fuel_percent_RE) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'boiler', attribute: 'fuel_percent_RE', value: '' });
          if(this.run.site.boiler.emissions_factor_lb_CO2_per_mmbtu) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_CO2_per_mmbtu', value: '' });
          if(this.run.site.boiler.emissions_factor_lb_NOx_per_mmbtu) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_NOx_per_mmbtu', value: '' });
          if(this.run.site.boiler.emissions_factor_lb_SO2_per_mmbtu) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_SO2_per_mmbtu', value: '' });
          if(this.run.site.boiler.emissions_factor_lb_PM_per_mmbtu) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_PM_per_mmbtu', value: '' });
          if(this.run.site.prime_generator.emissions_factor_lb_CO2_per_mmbtu) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_CO2_per_mmbtu', value: '' });
          if(this.run.site.prime_generator.emissions_factor_lb_NOx_per_mmbtu) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_NOx_per_mmbtu', value: '' });
          if(this.run.site.prime_generator.emissions_factor_lb_SO2_per_mmbtu) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_SO2_per_mmbtu', value: '' });
          if(this.run.site.prime_generator.emissions_factor_lb_PM_per_mmbtu) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_PM_per_mmbtu', value: '' });

          if(this.run.site.financial.CO2_cost_per_tonne) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'CO2_cost_per_tonne', value: '' });
          if(this.run.site.financial.NOx_onsite_fuelburn_cost_per_tonne) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'NOx_onsite_fuelburn_cost_per_tonne', value: '' });
          if(this.run.site.financial.NOx_grid_cost_per_tonne) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'NOx_grid_cost_per_tonne', value: '' });
          if(this.run.site.financial.SO2_onsite_fuelburn_cost_per_tonne) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'SO2_onsite_fuelburn_cost_per_tonne', value: '' });
          if(this.run.site.financial.SO2_grid_cost_per_tonne) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'SO2_grid_cost_per_tonne', value: '' });
          if(this.run.site.financial.PM25_onsite_fuelburn_cost_per_tonne) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'PM25_onsite_fuelburn_cost_per_tonne', value: '' });
          if(this.run.site.financial.PM25_grid_cost_per_tonne) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'PM25_grid_cost_per_tonne', value: '' });

          if(this.run.site.financial.CO2_cost_escalation_rate_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'CO2_cost_escalation_rate_fraction', value: '' });
          if(this.run.site.financial.NOx_cost_escalation_rate_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'NOx_cost_escalation_rate_fraction', value: '' });
          if(this.run.site.financial.SO2_cost_escalation_rate_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'SO2_cost_escalation_rate_fraction', value: '' });
          if(this.run.site.financial.PM25_cost_escalation_rate_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'financial', attribute: 'PM25_cost_escalation_rate_fraction', value: '' });

          window.scrollTo(0, document.getElementById('emissions_heading').offsetTop);
        }
      }
    },

    mounted: function() {
      //TODO: remove once bootstrap vue integrated
      if(this.run.site.electric_tariff.emissions_type == "hourly") {
        document.querySelectorAll("[href='#emissions_factor_hourly']")[0].click();
      } else if(this.run.site.electric_tariff.emissions_type == "annual") {
        document.querySelectorAll("[href='#emissions_factor_annual']")[0].click();
      } else if(this.run.site.electric_tariff.emissions_type == "uploaded") {
        document.querySelectorAll("[href='#emissions_factor_upload']")[0].click();
      }
      
      if (this.run.site.electric_tariff.cambium_emissions_type == "hourly") {
        document.querySelectorAll("[href='#cambium_emissions_factor_hourly']")[0].click();
      } else if (this.run.site.electric_tariff.cambium_emissions_type == "annual") {
        document.querySelectorAll("[href='#cambium_emissions_factor_annual']")[0].click();
      } else if (this.run.site.electric_tariff.cambium_emissions_type == "uploaded") {
        document.querySelectorAll("[href='#cambium_emissions_factor_upload']")[0].click();
      }

      this.toggleEmissionsYears();

      // for copied runs
      if (this.run.site.financial.analysis_years && !this.run.site.electric_tariff.cambium_levelization_years) {
        this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_levelization_years', value: this.run.site.financial.analysis_years });
      }

      if(this.run.site.electric_tariff.emissions_file_data) {
        document.getElementById("run_site_attributes_electric_tariff_attributes_emissions_file").value = this.run.site.electric_tariff.emissions_file_data;
      }

      if(this.run.site.electric_tariff.cambium_emissions_file_data) {
        document.getElementById("run_site_attributes_electric_tariff_attributes_cambium_emissions_file").value = this.run.site.electric_tariff.cambium_emissions_file_data;
      }

      // see RunsController method #param_transform_keys as to why this is needed
      // the same field api side has two different ways of being populated UI side
      // TODO: create pivotal story for API team to add `hourly_emissions_source` field
      // ideally we would just send the API something like ElectricTariff.hourly_emissions_source = 'northeast'
      // although that is not currently supported
      
      // commenting the below out for now as updateEmissionsHourlyProfile() only applies to the charts which have been disabled for now.
      // once co2 split out from that function will also need an if for that one I believe.
      // if(this.run.site.electric_tariff.emissions_type == "hourly" && this.run.site.latitude) {
      //   this.updateEmissionsHourlyProfile();
      // }

      if(this.run.site.electric_tariff.emissions_type == "uploaded" && this.run.site.electric_tariff.uploaded_emissions_factor_profile) {
        var profiles = JSON.parse(this.run.site.electric_tariff.uploaded_emissions_factor_profile);
        var payload = {
          nox: { series: profiles.nox.series },
          so2: { series: profiles.so2.series },
          pm25: { series: profiles.pm25.series },
        }

        this.$store.commit('updateObject', { object: 'emissionsUploadedProfile', value: payload });
      }

      if(this.run.site.electric_tariff.cambium_emissions_type == "uploaded" && this.run.site.electric_tariff.uploaded_cambium_emissions_factor_profile) {
        var profiles = JSON.parse(this.run.site.electric_tariff.uploaded_cambium_emissions_factor_profile);
        var payload = {
          co2: { series: profiles.co2.series },
        }

        this.$store.commit('updateObject', { object: 'cambiumEmissionsUploadedProfile', value: payload });
      }
    }
  }
</script>
