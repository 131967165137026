<template>
  <div class="form-horizontal">
    <p class="required-note"><abbr class="required">*</abbr> Required field</p>

    <h4><abbr class="required">*</abbr> Typical electrical load <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'section_header'"></tooltip></h4>
    <p>How would you like to enter the typical energy load profile?</p>

    <input
      v-model="run.site.load_profile.typical_load_type"
      type="hidden"
      name="run[site_attributes][load_profile_attributes][typical_load_type]" />

    <ul class="nav nav-tabs">
      <li class="active"><a data-toggle="tab" href="#simulated_building" @click="updateTypicalLoadType('simulated_building');"><i class="fas fa-home" aria-hidden="true"></i> Simulate Building</a></li>
      <li><a data-toggle="tab" href="#simulated_campus" @click="updateTypicalLoadType('simulated_campus');"><i class="fas fa-university" aria-hidden="true"></i> Simulate Campus</a></li>
      <li><a data-toggle="tab" href="#uploaded" @click="updateTypicalLoadType('uploaded');"><i class="fas fa-upload" aria-hidden="true"></i> Upload</a></li>
    </ul>
    <div class="tab-content">
      <div v-if="run.site.load_profile.typical_load_type == 'simulated_building'" class="tab-pane in active">
        <horizontal-select-input-nested-2
          association_1='site'
          association_2='load_profile'
          attribute='doe_reference_name'
          required=true
          v-on:input-change="updateAnnualKwh();getElecSimBuildingMonthlyLoad();updateYear();" />
        <div class="col-sm-offset-6 col-sm-6 simulated-load-options">
          <input
            v-model="run.site.load_profile.simulated_load_type"
            style="width:15px;"
            type="radio"
            value="annual"
            checked="checked"
            name="run[site_attributes][load_profile_attributes][simulated_load_type]"
            id="run_site_attributes_load_profile_attributes_simulated_load_type_annual"
            @click="updateLoadSlider()" />
          <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_attributes_simulated_load_type_annual">Annual</label>
          <input
            v-model="run.site.load_profile.simulated_load_type"
            class="simulated-monthly"
            style="width:15px;"
            type="radio"
            value="monthly"
            name="run[site_attributes][load_profile_attributes][simulated_load_type]"
            id="run_site_attributes_load_profile_attributes_simulated_load_type_monthly"
            @click="updateLoadSlider()" />
          <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_attributes_simulated_load_type_monthly">Monthly</label>
        </div>
        <horizontal-string-input-nested-2
          v-if="run.site.load_profile.simulated_load_type == 'annual'"
          association_1='site'
          association_2='load_profile'
          attribute='annual_kwh'
          ref='annualKwh'
          v-on:input-change="updateAnnualKwh()" />
        <monthly-inputs
          v-else="run.site.load_profile.simulated_load_type == 'monthly'"
          type="electric"
          v-on:input-change="getElecSimBuildingMonthlyLoad()"
          v-on:paste-inputs="pasteInputs('electric')" />
        <div class="form-group string optional run_site_load_profile_annual_kwh" style="margin-top: -15px; margin-bottom: 0;">
          <div class="col-sm-2 default-value-block" style="float: right;">
            <div v-if="run.site.load_profile.load_reduction !== '100.00' && userEnteredAnnualKwhValue" class="default-value" :data-default-value="userEnteredAnnualKwhValue" :data-value-display="userEnteredAnnualKwhValue">
              User value = {{ this.$store.getters.formatAsIntegerWithCommas({value: this.userEnteredAnnualKwhValue, decimals: 0}) }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="run.site.load_profile.typical_load_type == 'simulated_campus'" class="tab-pane" id="simulated_campus">
        <simulated-campus-table
          v-on:update-cold-load="getColdSimulatedLoadProfile()" />
      </div>
      <div v-else-if="!currentUser && run.site.load_profile.typical_load_type == 'uploaded'" class="tab-pane" id="uploaded">
        <input
          v-model="run.site.load_profile.loads_kw"
          type="hidden"
          name="run[site_attributes][load_profile_attributes][typical_uploaded_loads_kw]"/>

        <div class="form-group file required run_site_load_profile_typical_load_file">
          <div class="col-sm-6 control-label">
            <label class="file required" for="typical_load_file"><abbr title="Required" class="required">*</abbr> Custom load profile</label><a href="#" rel="tooltip" title="If 'Upload' is selected, users may upload a custom electric load profile for their site. Hourly, 30-minute, or 15-minute load data are accepted. The units must be in kW. See sample load profile for recommended format. For more information, please see the <a href='/tool/reopt-user-manual.pdf' target='_blank' rel='noopener noreferrer'>help manual</a>."><i class="fas fa-question-circle" aria-hidden="true"></i></a>
          </div>
          <div class="col-sm-4">
            <input
              class="form-control hidden"
              type="hidden"
              name="run[site_attributes][load_profile_attributes][typical_load_file]"
              id="run_site_attributes_load_profile_attributes_typical_load_file" />
            <input
              @change="parseCSV($event, 'electric')"
              class="form-control-file file required load-profile-file-upload"
              required="required"
              aria-required="true"
              type="file"
              name="run[site_attributes][load_profile_attributes][typical_load_file]"
              id="typical_load_file" />
            <!-- <p v-if="run.copied && this.run.site.load_profile.typical_load_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.load_profile.typical_load_file_data).metadata.filename }}</p> -->
            <p v-if="run.copied && this.run.site.load_profile.typical_load_file_name" class="help-block">Uploaded file: {{ this.run.site.load_profile.typical_load_file_name }}</p>
            <p class="help-block">
              <a style="font-size:12px;" href="/tool/load_profile_template.csv">Sample custom load profile</a>
            </p>
          </div>
        </div>
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='load_profile'
          v-on:input-change="updateYear();getDefaultOutageDateHour();"
          attribute='year' />
      </div>
      <div v-else-if="currentUser && run.site.load_profile.typical_load_type == 'uploaded'" class="tab-pane" id="uploaded">
        <div class="form-group select required">
          <div class="col-sm-6 control-label">
            <label class="select required" for="run_site_attributes_load_profile_attributes_typical_load_profile_id">
              <abbr title="Required" class="required">*</abbr> Custom load profile
            </label>
            <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'typical_load_file_data'"></tooltip>
          </div>
          <div class="col-sm-4">
            <select
              v-model="run.site.load_profile.typical_load_profile_id"
              @change="updateTypicalLoadProfile()"
              class="form-control select required"
              required="required"
              aria-required="true"
              name="run[site_attributes][load_profile_attributes][typical_load_profile_id]">
              <option v-for="loadProfile in typicalLoadProfiles" :value="loadProfile.id">
                {{ getTypicalLoadProfileName(loadProfile) }}
              </option>
            </select>
            <p class="help-block">
              <a style="font-size:12px;" href="/tool/load_profile_template.csv">Sample custom load profile</a>
            </p>
          </div>
        </div>
        <input v-model="run.site.load_profile.loads_kw" class="form-control hidden" type="hidden" name="run[site_attributes][load_profile_attributes][typical_uploaded_loads_kw]" />
        <div class="row">
          <div class="col-sm-offset-6 col-sm-6">
            <a @click="displayTypicalLoadProfileForm()" class="btn btn-primary">Upload typical load profile</a>
          </div>
        </div>
        <div class="row col-sm-offset-6" style="padding-left:15px;margin-top:15px;">
          <p>Upload and manage <a href="/tool/typical_load_profiles">your typical load profiles</a></p>
        </div>
      </div>
    </div>

    <div v-if="run.site.load_profile.typical_load_type == 'simulated_building' && run.site.load_profile.simulated_load_type == 'annual'" class="row">
      <div class="col-sm-6">
        <div>
          <a v-if="!elecLoadProfileLoading" @click="downloadLoadProfile('elecSimBuildAnnualLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download electric load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!elecLoadProfileLoading" @click="displayElecSimBuildingAnnualLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart electric load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile.typical_load_type == 'simulated_building' && run.site.load_profile.simulated_load_type == 'monthly'" class="row">
      <div class="col-sm-6">
        <div>
          <a v-if="!elecLoadProfileLoading" @click="downloadLoadProfile('elecSimBuildMonthlyLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download electric load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!elecLoadProfileLoading" @click="displayElecSimBuildingMonthlyLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart electric load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile.typical_load_type == 'simulated_campus'" >
      <div class="col-sm-6">
        <div>
          <a v-if="!elecLoadProfileLoading" @click="downloadLoadProfile('elecSimCampusLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download campus electric load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!elecLoadProfileLoading" @click="displayElecSimCampusingLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart campus electric load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile.typical_load_type == 'uploaded'" class="row">
      <div class="col-sm-6 col-sm-offset-6 text-right">
        <a v-if="!elecLoadProfileLoading" @click="displayElecUploadedLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart uploaded load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>

    <transition name="fade">
      <load-profile-modal v-if="showElecSimBuildAnnualLoadProfile"
        :loadType="'elecSimBuildAnnualLoadProfile'">
      </load-profile-modal>
    </transition>
    <transition name="fade">
      <load-profile-modal v-if="showElecSimBuildMonthlyLoadProfile"
        :loadType="'elecSimBuildMonthlyLoadProfile'">
      </load-profile-modal>
    </transition>
    <transition name="fade">
      <load-profile-modal v-if="showElecUploadedLoadProfile"
        :loadType="'elecUploadedLoadProfile'">
      </load-profile-modal>
    </transition>
    <transition name="fade">
      <load-profile-modal v-if="showElecSimCampusLoadProfile"
        :loadType="'elecSimCampusLoadProfile'">
      </load-profile-modal>
    </transition>
    <transition name="fade">
      <typical-load-profile-form v-if="showTypicalLoadProfileForm"
        v-on:update-selected-typ-load-profile="updateSelectedTypLoadProfile">
      </typical-load-profile-form>
    </transition>

    <br>
    <div v-if="!run.grid">
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='load_profile'
        attribute='min_load_met_annual_fraction' />
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='load_profile'
        attribute='operating_reserve_required_fraction' />
    </div>

    <br>
    <h4>Electrical load adjustment</h4>
    <div class="form-group">
      <div class="col-sm-6 control-label" style="margin-top:26px;">
        <strong>Adjust electricity consumption</strong><tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'adjust_elec_consump'"></tooltip>
      </div>
      <div class="col-sm-4" style="margin-top:36px;">
        <veeno
          v-model="run.site.load_profile.load_reduction"
          id="load-reduction-slider"
          pipsy
          :step="1"
          :handles="parseInt(run.site.load_profile.load_reduction)"
          :range = "{ min: 0, max: 200 }" >
          {{ Number(run.site.load_profile.load_reduction).toFixed(1) }}% of original consumption
        </veeno>
        <input
          v-model="run.site.load_profile.load_reduction"
          class="form-control hidden load-reduction"
          type="hidden"
          name="run[site_attributes][load_profile_attributes][load_reduction]" />
      </div>
    </div>
    <br><br><br>

    <div v-if="run.analyze_chp || run.analyze_ghp || run.analyze_ashp_space">
      <heating-load-profile-inputs />
    </div>

    <div v-if="displayColdLP">
      <cooling-load-profile-inputs 
        ref='coolingLoadInputs'/>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
  import HorizontalStringInputNested3 from './inputs/HorizontalStringInputNested3';
  import HorizontalSelectInputNested2 from './inputs/HorizontalSelectInputNested2';
  import HorizontalSelectInputNested3 from './inputs/HorizontalSelectInputNested3';
  import LoadProfileModal from './modals/LoadProfileModal';
  import SimulatedCampusTable from './inputs/SimulatedCampusTable';
  import Tooltip from './Tooltip';
  import MonthlyInputs from './inputs/MonthlyInputs';
  import TypicalLoadProfileForm from './../typical_load_profile/TypicalLoadProfileForm';
  import HeatingLoadProfileInputs from './inputs/HeatingLoadProfileInputs';
  import CoolingLoadProfileInputs from './inputs/CoolingLoadProfileInputs';
  import veeno from 'veeno';
  import 'nouislider/distribute/nouislider.min.css';

  export default {
    components: {
      HorizontalStringInputNested2,
      HorizontalStringInputNested3,
      HorizontalSelectInputNested2,
      HorizontalSelectInputNested3,
      LoadProfileModal,
      SimulatedCampusTable,
      Tooltip,
      MonthlyInputs,
      HeatingLoadProfileInputs,
      CoolingLoadProfileInputs,
      TypicalLoadProfileForm,
      veeno
    },

    data: function() {
      return {
        unadjustedUploadedLP: [],
        tonhourToKwht: 3.51685,
      }
    },

    computed: {
      ...mapGetters([
        'currentUser',
        'typicalLoadProfiles',
        'placeholders',
        'userEnteredAnnualKwhValue',
        'userEnteredMonthlyKwhValues',
        'run',
        'tooltips',
        'urlRoot',
        'year',

        'elecLoadProfile',
        'elecLoadProfileLoading',
        'elecSimBuildAnnualLoadProfile',
        'elecSimBuildMonthlyLoadProfile',
        'elecSimCampusLoadProfile',
        'elecUploadedLoadProfile',

        'showElecSimBuildAnnualLoadProfile',
        'showElecSimBuildMonthlyLoadProfile',
        'showElecSimCampusLoadProfile',
        'showElecUploadedLoadProfile',

        'showTypicalLoadProfileForm',
      ]),
      displayColdLP: function() {
        const analyzeCws = this.run.analyze_cws;
        const chpWithAbsChiller = this.run.site.chp && this.run.site.chp.analyze_abs_chiller;
        const analyzeGhp = this.run.analyze_ghp;
        const ashpWithCooling = this.run.analyze_ashp_space &&
            (this.run.site.ashp_space_heater.ashp_configuration === 3 ||
                this.run.site.ashp_space_heater.ashp_configuration === 4);

        return analyzeCws || chpWithAbsChiller || analyzeGhp || ashpWithCooling;
      },
      watchDependencies:  function() {
        return this.run.analyze_chp,
        this.run.analyze_prime_generator,
        this.run.site.latitude, 
        this.run.site.longitude,
        this.run.site.load_profile.simulated_load_type, 
        this.run.site.load_profile.typical_load_type,
        new Date();
      },
    },

    methods: {
      updateAnnualKwh: function() {
        if(this.run.site.load_profile.simulated_load_type != 'annual') {
          return false;
        }

        if(this.run.site.address && this.run.site.load_profile.doe_reference_name) {
          var self = this;
          $.ajax({
            url: self.urlRoot + '/annual-kwh',
            contentType: 'application/json',
            data: {
              latitude: this.run.site.latitude,
              longitude: this.run.site.longitude,
              doe_reference_name: this.run.site.load_profile.doe_reference_name,
            },
            success: function handleResults(data) {
              if(!self.run.site.load_profile.annual_kwh) {
                self.$refs.annualKwh.toggleDisplayDefault();
              }

              self.$store.commit('updatePlaceholder', {
                association_1: 'site',
                association_2: 'load_profile',
                attribute: 'annual_kwh',
                value: self.$store.getters.formatAsIntegerWithCommas({ value: data.toFixed(0) })
              });

              // this logic is for copied runs to display the correct user adjusted annual_kwh value or the default adjusted annual_kwh value.
              // It does produce some weird results however if the load slider is changed from 100% and then the user tries to input a value.
              // I think the only way to work around that bug would be to create a rails db variable for the user input value, then the code below
              // might not even be needed at all. Can instead just check for a user input value and update that wherever you see userEnteredAnnualKwhValue?
              if (parseInt(self.placeholders.site.load_profile.annual_kwh.split(',').join('')) !== parseInt((self.run.site.load_profile.annual_kwh / (self.run.site.load_profile.load_reduction / 100.0)).toFixed(0))) {
                var userValue = (self.run.site.load_profile.annual_kwh / (self.run.site.load_profile.load_reduction / 100.0)).toString();
                if (userValue === '0') {
                  userValue = ''
                }
                self.$store.commit('updateObject', { object: 'userEnteredAnnualKwhValue', value: userValue });
                // self.$store.commit('updateObject', { object: 'userEnteredAnnualKwhValue', value: self.run.site.load_profile.annual_kwh.toString() });
              }

              //Add conditional so simulated_load endpoint is not called twice unnecessarily
              self.getElecSimBuildingAnnualLoad();

              if (self.run.site.load_profile.load_reduction != 100) {
                self.updateAnnualKwhValueFromSlider();
              }
            },
            error: function() {
              return false;
            },
          })
        }
      },
      updateStore: function(attr, value) {
        var payload = {
          association_1: 'site',
          association_2: 'load_profile',
          attribute: attr,
          value: value,
        }

        this.$store.commit('updateValueNested2', payload);
      },
      displayElecSimBuildingAnnualLP: function() {
        // display electric simulated building load profile
        if(!this.run.site.load_profile.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude) {
          bootbox.alert('A load profile must be selected or uploaded first.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showElecSimBuildAnnualLoadProfile', value: true });
      },
      allMonthlyInputsPresent: function(type) {
        var values = [];
        for(var i = 1; i <= 12; i++) {
          if(type == "electric") {
            values.push(this.run.site.load_profile[`energy_use_monthly_${i}`]);
          }
        }

        if(values.includes("") || values.includes(null) || values.includes(undefined)) {
          return false;
        } else {
          return true;
        }
      },
      displayElecSimBuildingMonthlyLP: function() {
        // display electric simulated building monthly load profile
        if(!this.allMonthlyInputsPresent('electric') || !this.run.site.load_profile.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude) {
          bootbox.alert('Please verify that all monthly values for energy consumption have been entered, a Type of Building has been selected, and a Site Location entered.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showElecSimBuildMonthlyLoadProfile', value: true });
      },
      displayElecSimCampusingLP: function() {
        // display electric simulated campus load profile
        if(!this.elecSimCampusLoadProfile.loadsKw) {
          bootbox.alert('All building information along with the total energy consumption must be entered first. Verify all percents sum to 100.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showElecSimCampusLoadProfile', value: true });
      },
      displayElecUploadedLP: function() {
        // display electric uploaded load profile
        if(!this.elecUploadedLoadProfile.loadsKw) {
          bootbox.alert('A load profile must be selected or uploaded first.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showElecUploadedLoadProfile', value: true });
      },
      getElecSimBuildingAnnualLoad: function() {
        this.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: true });
        var self = this;
        var params = {
            latitude: this.run.site.latitude,
            longitude: this.run.site.longitude,
            doe_reference_name: this.run.site.load_profile.doe_reference_name,
        }

        // due to the new functionality of the load_reduction slider autopopulating the adjusted annual_kwh value,
        // only send annual_kwh params if load_reduction slider is 100 and there is a user entered value. Otherwise,
        // on copy, if the load_reduction is something other than 100, the min/max/mean/loads_kw values are different
        // than the initial input page values.
        if(this.run.site.load_profile.load_reduction == 100 && this.run.site.load_profile.annual_kwh) {
          params['annual_kwh'] = this.run.site.load_profile.annual_kwh;
        }

        $.ajax({
          url: self.urlRoot + "/simulated-annual-elec-load",
          data: params,
          success: function(data) {
            var payload = {
              loadsKw: data.loads_kw,
              minKw: data.min_kw,
              meanKw: data.mean_kw,
              maxKw: data.max_kw,
            }
            self.$store.commit('updateObject', { object: 'elecSimBuildAnnualLoadProfile', value: payload });
            if (self.displayColdLP) {
              self.$refs.coolingLoadInputs.getColdSimulatedLoadProfile();
              self.$refs.coolingLoadInputs.getColdCustomAnnualPctLoad();
              self.$refs.coolingLoadInputs.getColdCustomMonthlyPctLoad();
            }
            self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
          },
          error: function() {
            self.$store.commit('updateObject', { object: 'elecSimBuildAnnualLoadProfile', value: {} });
            if (self.displayColdLP) {
              self.$refs.coolingLoadInputs.getColdSimulatedLoadProfile();
              self.$refs.coolingLoadInputs.getColdCustomAnnualPctLoad();
              self.$refs.coolingLoadInputs.getColdCustomMonthlyPctLoad();
            }
            self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
          }
        })
      },
      getElecSimBuildingMonthlyLoad: function() {
        if(this.run.site.load_profile.simulated_load_type != 'monthly') {
          return false;
        }

        if(this.allMonthlyInputsPresent('electric') && this.run.site.latitude && this.run.site.longitude && this.run.site.load_profile.doe_reference_name) {
          this.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: true });

          var monthly_totals_kwh = [];
          for(var i = 1; i <= 12; i++) {
            monthly_totals_kwh.push(this.run.site.load_profile[`energy_use_monthly_${i}`]);
          }
          this.$store.commit('updateObject', { object: 'userEnteredMonthlyKwhValues', value: monthly_totals_kwh });

          var params = {
            latitude: this.run.site.latitude,
            longitude: this.run.site.longitude,
            doe_reference_name: this.run.site.load_profile.doe_reference_name,
            monthly_totals_kwh: monthly_totals_kwh,
          }

          var self = this;
          $.ajax({
            url: self.urlRoot + "/simulated-monthly-elec-load",
            data: params,
            success: function(data) {
              var payload = {
                loadsKw: data.loads_kw,
                minKw: data.min_kw,
                meanKw: data.mean_kw,
                maxKw: data.max_kw,
              }
              self.$store.commit('updateObject', { object: 'elecSimBuildMonthlyLoadProfile', value: payload });
              if (self.displayColdLP) {
                self.$refs.coolingLoadInputs.getColdSimulatedLoadProfile();
                self.$refs.coolingLoadInputs.getColdCustomAnnualPctLoad();
                self.$refs.coolingLoadInputs.getColdCustomMonthlyPctLoad();
              }
              self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });

              // for copied runs
              if (self.run.site.load_profile.load_reduction != 100) {
                var monthly_values = [];
                self.userEnteredMonthlyKwhValues.forEach(function(value, i) {
                  monthly_values.push((self.run.site.load_profile[`energy_use_monthly_${i + 1}`] / (self.run.site.load_profile.load_reduction / 100.0)).toFixed(0));
                })

                self.$store.commit('updateObject', { object: 'userEnteredMonthlyKwhValues', value: monthly_values });
                self.updateMonthlyKwhValuesFromSlider();
              }
            },
            error: function() {
              self.$store.commit('updateObject', { object: 'elecSimBuildMonthlyLoadProfile', value: {} });
              if (self.displayColdLP) {
                self.$refs.coolingLoadInputs.getColdSimulatedLoadProfile();
                self.$refs.coolingLoadInputs.getColdCustomAnnualPctLoad();
                self.$refs.coolingLoadInputs.getColdCustomMonthlyPctLoad();
              }
              self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
            }
          })
        }
      },
      getColdSimulatedLoadProfile: function() {
        if (this.displayColdLP) {
          this.$refs.coolingLoadInputs.getColdSimulatedLoadProfile();
        }
      },
      updateTypicalLoadType: function(type) {
        this.updateStore('typical_load_type', type);
        this.updateYear();
        if (this.displayColdLP) {
          this.$refs.coolingLoadInputs.getColdSimulatedLoadProfile();
          this.$refs.coolingLoadInputs.getColdCustomAnnualPctLoad();
          this.$refs.coolingLoadInputs.getColdCustomMonthlyPctLoad(); 
        }
      },
      
      //new comment (2023): the updated load code block is commented out due to
      //buggy behavior wherein the loads don't get updated automatically after
      //uploading load csv and then adjusting the slider, but get updated if
      //csv is uploaded after slider is adjusted, the load gets updated to the
      //adjusted ratio. This is not what we want, so changed behaviour to only
      //update loads in POST to API. Otherwise, only the charts show the adjustement

      //updateUploadedLoadsKw: function() {
      //  var self = this;
      //  var newLoad = this.unadjustedUploadedLP.map(function(el, i) {
      //    return el * (self.run.site.load_profile.load_reduction / 100.0);
      //  })
      //
      //  this.updateStore('loads_kw', newLoad);
      //},
      parseCSV: function(event, type) {
        var file = event.target.files[0];

        if(file && file.name.split(".")[1] != "csv") {
          bootbox.alert("Uploaded file " + file.name + " must be a .csv");
          this.run.site.load_profile.loads_kw = [];
          event.target.value = "";

          return false;
        }

        if(file) {
          var self = this;
          Papa.parse(file, {
            delimiter: ",",
            complete: function(results) {
              try {
                if(results.errors && results.errors.length > 0) {
                  this.run.site.load_profile.loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The load profile could not be parsed. File must be a CSV.<br><br>Error (row ' + results.errors[0].row + '): ' + results.errors[0].message);

                  return false;
                }

                var rows = results.data;
                var values = [];
                var hasColumns = false;
                for(var i = 0, len = rows.length; i < len; i++) {
                  var row = rows[i];

                  // Detect header columns.
                  if(i < 3) {
                    if(row[0] && typeof row[0] === 'string') {
                      var value = row[0].toLowerCase();

                      // Detect when the CSV has multiple columns. Otherwise assume
                      // the CSV is just straight list of values.
                      if(value.indexOf('hour') !== -1) {
                        hasColumns = true;
                      }

                      // Skip header columns.
                      if(value.indexOf('hour') !== -1 || value.indexOf('directions') !== -1) {
                        continue;
                      }
                    }
                  }

                  // Skip empty rows.
                  if(row.join('') === '') {
                    continue;
                  }

                  // Ensure the values are only numbers.
                  var valueIndex = (hasColumns) ? 1 : 0;
                  var value = parseFloat(row[valueIndex]);
                  if(isNaN(value)) {
                    self.run.site.load_profile.loads_kw = [];
                    event.target.value = "";
                    bootbox.alert('The load profile must contain only numeric values. Could not parse value at row ' + (i + 1) + ': "' + row[valueIndex] + '"');

                    return false;
                  }
                  values.push(value);
                }

                // Validate the file contains the expected number of value rows.
                var valuesCount = values.length;
                if(valuesCount !== 8760 && valuesCount !== 17520 && valuesCount !== 35040) {
                  self.run.site.load_profile.loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The load profile must contain either 8,760 values (hourly data), 17,520 values (30-minute data), or 35,040 values (15-minute data) of numeric values.')

                  return false;
                }

                if(type == 'electric') {
                  // old comment: kinda funky but we have to preserve the unadjusted load profile
                  // (without load reduction factored in) for scenario where user adjusts
                  // the load reduction twice - otherwise we are adjusting the already
                  // adjusted series (as opposed to the original)

                  //new comment (2023): the updated load code block is commented out due to
                  //buggy behavior wherein the loads don't get updated automatically after
                  //uploading load csv and then adjusting the slider, but get updated if
                  //csv is uploaded after slider is adjusted, the load gets updated to the
                  //adjusted ratio. This is not what we want, so changed behaviour to only
                  //update loads in POST to API. Otherwise, only the charts show the adjustement

                  //self.unadjustedUploadedLP = values;
                  //var adjustedValues = values.map(function(el, i) {
                  //  return el * (self.run.site.load_profile.load_reduction / 100.0);
                  //});
                  self.updateUploadedLoadProfile(values, 'electric');
                  self.updateStore('loads_kw', JSON.stringify(values));
                  self.$store.commit('updateValueNested2', {association_1: 'site', association_2: 'load_profile', attribute: 'typical_load_file_name', value: file.name});
                  self.displayElecLPLoading();
                }
              } finally {
              }
            }
          })
        }
      },
      updateUploadedLoadProfile: function(values, type) {
        var avgValue = 0;
        var minValue;
        var maxValue;
        for(var i = 0; i < values.length; i++) {
          var value = values[i];
          avgValue += value;

          if(minValue === undefined || value < minValue) {
            minValue = value;
          }

          if(maxValue === undefined || value > maxValue) {
            maxValue = value;
          }
        }
        avgValue = avgValue / values.length;

        if(type == 'electric') {
          var payload = {
            loadsKw: values,
            minKw: minValue,
            meanKw: avgValue,
            maxKw: maxValue,
          }

          this.$store.commit('updateObject', { object: 'elecUploadedLoadProfile', value: payload });
          if (this.displayColdLP) {
            this.$refs.coolingLoadInputs.getColdSimulatedLoadProfile();
            this.$refs.coolingLoadInputs.getColdCustomAnnualPctLoad();
            this.$refs.coolingLoadInputs.getColdCustomMonthlyPctLoad();
          }
        }
      },
      displayElecLPLoading: function() {
        var type = this.run.site.load_profile.typical_load_type;
        var simBuildType = this.run.site.load_profile.simulated_load_type;
        var elecSimBuildingAnnualLP = type == "simulated_building" && simBuildType == "annual" && this.elecSimBuildAnnualLoadProfile.loadsKw;
        var elecSimBuildingMonthlyLP = type == "simulated_building" && simBuildType == "monthly" && this.elecSimBuildMonthlyLoadProfile.loadsKw;
        var elecSimCampusLP = type == "simulated_campus" && this.elecSimCampusLoadProfile.loadsKw;
        var elecUploadedLP = type == "uploaded" && this.elecUploadedLoadProfile.loadsKw;

        if(elecSimBuildingAnnualLP || elecSimBuildingMonthlyLP || elecSimCampusLP || elecUploadedLP) {
          this.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: true });
          var self = this;
          setTimeout(function() {
            self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
          }, 800);
        }
      },
      downloadLoadProfile: function(loadProfile) {
        if(loadProfile.includes("elec")) {
          var values = this[loadProfile].loadsKw;
          var rows = [ ['Hour', 'Electric Load (kW)'] ];
        }
        if(!values || values.length == 0) {
          bootbox.alert("A load profile must be selected or uploaded first.");
          return false;
        }

        for(var i = 0, len = values.length; i < len; i++) {
          rows.push([i + 1, values[i]]);
        }

        var csv = Papa.unparse(rows);
        var blob = new Blob([csv], {
          type: 'text/csv;charset=utf-8',
        });
        saveAs(blob, 'REopt Load Profile.csv');
      },
      getTypicalLoadProfileName(loadProfile) {
        var name = JSON.parse(loadProfile.file_data).metadata.filename.split(".")[0];

        return `${name} (${loadProfile.year})`
      },
      updateTypicalLoadProfile() {
        this.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: true });
        var id = this.run.site.load_profile.typical_load_profile_id;
        if(!id) {
          this.$store.commit('updateObject', { object: 'elecUploadedLoadProfile', value: {} });
          this.updateStore('loads_kw', []);
          this.unadjustedUploadedLP = [];
          this.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });

          return false;
        }

        var loadProfile = this.typicalLoadProfiles.find(function(loadProfile) {
          return loadProfile.id == id;
        })
        var payload = {
          loadsKw: loadProfile.load_kw.map(function(el) {
            // for the chart to find the correct min/max/avg values,
            // need to round each value up to four decimal places due to the precision/scale 
            // restriction in the rails db for min_load, max_load, and avg_load
            var roundedEl = Math.round(el * 10000) / 10000
            return parseFloat(roundedEl);
          }),
          minKw: loadProfile.min_load,
          meanKw: loadProfile.avg_load,
          maxKw: loadProfile.max_load,
        }
        this.$store.commit('updateObject', { object: 'elecUploadedLoadProfile', value: payload });
        if (this.displayColdLP) {
          this.$refs.coolingLoadInputs.getColdSimulatedLoadProfile();
        }
        this.updateStore('loads_kw', JSON.stringify(payload.loadsKw));
        this.unadjustedUploadedLP = payload.loadsKw;
        this.updateYear();

        var self = this;
        setTimeout(function() {
          self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
        }, 800);
      },
      displayTypicalLoadProfileForm: function() {
        this.$store.commit('updateObject', { object: 'showTypicalLoadProfileForm', value: true });
      },
      updateSelectedTypLoadProfile: function(event, payload) {
        var fileData = JSON.parse(payload.file_data);
        var fileName = fileData.metadata.filename.split(".")[0];
        var id = payload.id;

        var newLoadProfile = {
          id: id,
          file_data: payload.file_data,
          year: payload.year,
          load_kw: payload.load_kw,
          min_load: payload.min_load,
          avg_load: payload.avg_load,
          max_load: payload.max_load,
        }

        this.typicalLoadProfiles.push(newLoadProfile);
        this.updateStore('typical_load_profile_id', id);
        //this.updateStore('typical_load_profile_data', fileName);
        this.updateStore('loads_kw', JSON.stringify(payload.load_kw));
        this.updateTypicalLoadProfile();
      },
      updateYear: function() {
        var year;
        if(this.run.site.load_profile.typical_load_type == "simulated_building" || this.run.site.load_profile.typical_load_type == "simulated_campus") {
          year = 2017;
        } else {
          if(this.currentUser && this.run.site.load_profile.typical_load_profile_id) {
            var self = this;
            year = this.typicalLoadProfiles.find(function(el) {
              return el.id == self.run.site.load_profile.typical_load_profile_id;
            }).year
          } else {
            year = this.run.site.load_profile.year || 2022;
            this.placeholders.site.load_profile.year = "2022";
          }
        }

        this.$store.commit('updateObject', { object: 'year', value: year });
        this.$store.commit('updateFlatPickrYear', year);
      },
      getDefaultOutageDateHour: function() {
        if(!this.run.site.load_profile.outage_duration || this.run.site.load_profile.custom_multiple_outage_dates || this.run.site.load_profile.number_of_outages == 1) {
          return false;
        } else if ((this.elecSimBuildAnnualLoadProfile.loadsKw && this.elecSimBuildAnnualLoadProfile.loadsKw.length == 0) && (this.elecSimBuildMonthlyLoadProfile.loadsKw && this.elecSimBuildMonthlyLoadProfile.loadsKw.length == 0) && (this.elecSimCampusLoadProfile.loadsKw && this.elecSimCampusLoadProfile.loadsKw.length == 0) && (this.elecUploadedLoadProfile.loadsKw && this.elecUploadedLoadProfile.loadsKw.length == 0) && this.run.site.load_profile.critical_loads_kw.length == 0) { 
          return false;
        }
        var self = this;
        var params = {
          seasonal_peaks: 1,
          outage_duration: this.run.site.load_profile.outage_duration,
          start_not_center_on_peaks: 0,
        }
        var typicalLoadType = this.run.site.load_profile.typical_load_type;
        var criticalLoadType = this.run.site.load_profile.critical_load_type;
        if(typicalLoadType == "simulated_building" && criticalLoadType == "percent" && this.run.site.load_profile.simulated_load_type == "annual" && this.elecSimBuildAnnualLoadProfile.loadsKw) {
          params['critical_load'] = this.calculateCriticalPercentLP("elecSimBuildAnnualLoadProfile")['loadsKw'];
        } else if(typicalLoadType == "simulated_building" && criticalLoadType == "percent" && this.run.site.load_profile.simulated_load_type == "monthly" && this.elecSimBuildMonthlyLoadProfile.loadsKw) {
          params['critical_load'] = this.calculateCriticalPercentLP("elecSimBuildMonthlyLoadProfile")['loadsKw'];
        } else if(typicalLoadType == "simulated_campus" && criticalLoadType == "percent" && this.elecSimCampusLoadProfile.loadsKw) {
          params['critical_load'] = this.calculateCriticalPercentLP("elecSimCampusLoadProfile")['loadsKw'];
        } else if(typicalLoadType == "uploaded" && criticalLoadType == "percent" && this.elecUploadedLoadProfile.loadsKw) {
          params['critical_load'] = this.calculateCriticalPercentLP("elecUploadedLoadProfile")['loadsKw'];
        } else if(criticalLoadType == "upload") {
          params['critical_load'] = this.run.site.load_profile.critical_loads_kw;
        }

        // this variable (time_steps_per_hour) doesn't seem to be used anywhere after it's declared... not sure what it's for.
        var time_steps_per_hour = params['critical_load'].length/8760;

        $.ajax({
          url: self.urlRoot + "/peak-load-outage-times",
          method: 'POST',
          data: JSON.stringify(params),
          contentType: "application/json",
          processData: false,
          success: function handleResults(data) {
            var year = (self.run.site.load_profile.year) ? Number(self.run.site.load_profile.year) : Number(self.year);
            var outagedate = new Date(Date.UTC(year, 0, 1));
            for(var i=0; i < 4; i++){
              var outagedate = new Date(Date.UTC(year, 0, 1));          
              outagedate.setUTCSeconds(Math.floor(data.outage_start_time_steps[i]/24) * 24 * 3600);
              var payload = {
                index: i,
                outage_duration: Number(self.run.site.load_profile.outage_duration),
                outage_start_date: `${year}-${outagedate.getUTCMonth()+1}-${outagedate.getUTCDate()}`,
                outage_start_hour: data.outage_start_time_steps[i]%24,
                outage_start_time_step: data.outage_start_time_steps[i],
              }
              self.$store.commit('updateOutageDateHour', payload);
            }
          },
          error: function(data) {
            return false;
          },
        })
      },
      calculateCriticalPercentLP: function(typicalLoadProfile) {
        var percent = (this.run.site.load_profile.critical_load_fraction / 100.0) || (this.placeholders.site.load_profile.critical_load_fraction / 100.0);
        var self = this;

        return {
          loadsKw: self[typicalLoadProfile].loadsKw.map(function(el, i) {
            return el * percent;
          }),
          minKw: self[typicalLoadProfile].minKw * percent,
          meanKw: self[typicalLoadProfile].meanKw * percent,
          maxKw: self[typicalLoadProfile].maxKw * percent,
        }
      },
      updateLoadSlider: function() {
        if (this.run.site.load_profile.load_reduction != 100) {
          this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'load_profile', attribute: 'load_reduction', value: '100.00' });
          $('#load-reduction-slider')[0].noUiSlider.set("100.00");

          if (this.run.site.load_profile.annual_kwh && !this.userEnteredAnnualKwhValue && this.run.site.load_profile.simulated_load_type === "annual") {
            this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'load_profile', attribute: 'annual_kwh', value: null });
          } else if (this.userEnteredAnnualKwhValue && this.run.site.load_profile.simulated_load_type === "annual") {
            this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'load_profile', attribute: 'annual_kwh', value: this.userEnteredAnnualKwhValue });
          }

          if (this.userEnteredMonthlyKwhValues.length !== 0 && this.run.site.load_profile.simulated_load_type === "monthly") {
            for(var i = 0; i < 12; i++) {
              this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'load_profile', attribute: `energy_use_monthly_${i + 1}`, value: this.userEnteredMonthlyKwhValues[i] });
            }
          }
        }
      },
      updateAnnualKwhValueFromSlider: function() {
        if (this.run.site.load_profile.simulated_load_type == "annual" && this.run.site.load_profile.typical_load_type != "simulated_campus" && !this.userEnteredAnnualKwhValue && this.run.site.load_profile.load_reduction != 100) {
          var annualKwh = parseInt(this.placeholders.site.load_profile.annual_kwh.split(',').join(''));
          var loadReductionPct = this.run.site.load_profile.load_reduction / 100;
          var adjustedAnnualKwh = annualKwh * loadReductionPct;

          this.$store.commit('updateValueNested2', {
            association_1: 'site',
            association_2: 'load_profile',
            attribute: 'annual_kwh',
            // Cannot say Math.round(adjustedAnnualKwh), need to leave at least one decimal place in this value 
            // or else on copy sometimes the default value and the calculated value do not match and it thinks
            // there was a user entered value when there wasn't.
            value: adjustedAnnualKwh.toFixed(1)
          });
        } else if (this.userEnteredAnnualKwhValue && this.run.site.load_profile.simulated_load_type == "annual" && this.run.site.load_profile.typical_load_type != "simulated_campus" && this.run.site.load_profile.load_reduction != 100) {
          var annualKwh = parseInt(this.userEnteredAnnualKwhValue.split(',').join(''));
          var loadReductionPct = this.run.site.load_profile.load_reduction / 100;
          var adjustedAnnualKwh = annualKwh * loadReductionPct;

          this.$store.commit('updateValueNested2', {
            association_1: 'site',
            association_2: 'load_profile',
            attribute: 'annual_kwh',
            value: adjustedAnnualKwh.toFixed(1)
          });
        } else if (this.userEnteredAnnualKwhValue && this.run.site.load_profile.simulated_load_type == "annual" && this.run.site.load_profile.typical_load_type != "simulated_campus" && this.run.site.load_profile.load_reduction == 100) {
          this.$store.commit('updateValueNested2', {
            association_1: 'site',
            association_2: 'load_profile',
            attribute: 'annual_kwh',
            value: this.userEnteredAnnualKwhValue
          });
        } else {
          this.$store.commit('updateValueNested2', {
            association_1: 'site',
            association_2: 'load_profile',
            attribute: 'annual_kwh',
            value: ''
          });
        }
      },
      updateMonthlyKwhValuesFromSlider: function() {
        if (this.userEnteredMonthlyKwhValues.length !== 0 && this.run.site.load_profile.simulated_load_type == "monthly" && this.run.site.load_profile.typical_load_type != "simulated_campus" && this.run.site.load_profile.load_reduction != 100) {
          var self = this;
          this.userEnteredMonthlyKwhValues.forEach(function(value, i) {
            var monthlyKwh = parseInt(value.split(',').join(''));
            var loadReductionPct = self.run.site.load_profile.load_reduction / 100;
            var adjustedMonthlyKwh = monthlyKwh * loadReductionPct;

            self.$store.commit('updateValueNested2', {
              association_1: 'site',
              association_2: 'load_profile',
              attribute: `energy_use_monthly_${i + 1}`,
              value: adjustedMonthlyKwh.toFixed(1)
            });
          })
        } else if (this.userEnteredMonthlyKwhValues.length !== 0 && this.run.site.load_profile.simulated_load_type == "monthly" && this.run.site.load_profile.typical_load_type != "simulated_campus" && this.run.site.load_profile.load_reduction == 100) {
          var self = this;
          this.userEnteredMonthlyKwhValues.forEach(function(value, i) {
            self.$store.commit('updateValueNested2', {
              association_1: 'site',
              association_2: 'load_profile',
              attribute: `energy_use_monthly_${i + 1}`,
              value: value
            });
          })
        }
      },
      pasteInputs: function(type) {
        event.preventDefault();
        var self = this;
        var clip = event.clipboardData.getData('text');

        if (clip.includes('\r\n')) {
          var clipArray = clip.split('\r\n');
          if (clipArray.length > 12) {
            // remove any values at the end of the array if they exceed the 12 month count so the first 12 paste to the correct months
            clipArray.splice(12, clipArray.length - 12);
          }
          // remove any commas within the numbers if they exist
          clipArray.forEach(function(item, i) {
            clipArray[i] = item.split(',').join('');
          })

          // set the month number to be assigned dynamically below
          var month = parseInt(event.target.name.replaceAll(']', '').split('[')[3].split('_')[3]);
        } else {
          // remove any commas if they exist for single value paste
          var clipValue = clip.split(',').join('');
          var attributeName = event.target.name.replaceAll(']', '').split('[')[3];
        }

        if (clipArray) {
          clipArray.forEach(function(item, i) {
            // Ensure the values are only numbers.
            if (isNaN(parseFloat(item))) {
              bootbox.alert('The monthly input must contain only numeric values. Could not parse value "' + item + '"');
              return false;
            } else {
              self.$store.commit('updateValueNested2', {
                association_1: 'site',
                association_2: 'load_profile',
                attribute: `energy_use_monthly_${i + month}`,
                value: item
              });
            }
          });
        } else {
          // for single input paste
          self.$store.commit('updateValueNested2', {
            association_1: 'site',
            association_2: 'load_profile',
            attribute: attributeName,
            value: clipValue
          });
        }

        this.getElecSimBuildingMonthlyLoad();
      }
    },

    watch: {
      watchDependencies: function() {
        var type = this.run.site.load_profile.typical_load_type;
        if(type == "simulated_building" && this.run.site.load_profile.simulated_load_type == "annual") {
          this.updateAnnualKwh();
        } else if(type == "simulated_building" && this.run.site.load_profile.simulated_load_type == "monthly") {
          this.getElecSimBuildingMonthlyLoad();
        }
        // if no current_user, need to remove the user input load profile year when toggle from uploaded to simulate building or campus
        // or the outage dates will not update automatically 
        if ((type == "simulated_building" || type == "simulated_campus") && this.run.site.load_profile.year) {
          this.run.site.load_profile.year = null;
        }
      },
    },

    mounted: function() {
      var self = this;

      // definitely not pretty but not certain how to style pips without digging
      // into package's css file :/
      // this is also why the click events are needed to be added after html added
      var pip50 = "<div class='noUi-value noUi-value-horizontal noUi-value-large' data-value='50' style='left:25%;'>50</div>";
      var pip50Marker = "<div class='noUi-marker noUi-marker-horizontal noUi-marker-large' style='left:25%;'></div>";
      var pip100 = "<div class='noUi-value noUi-value-horizontal noUi-value-large' data-value='200' style='left:50%;'>100</div>";
      var pip100Marker = "<div class='noUi-marker noUi-marker-horizontal noUi-marker-large' style='left:50%;'></div>";
      var pip150 = "<div class='noUi-value noUi-value-horizontal noUi-value-large' data-value='150' style='left:75%;'>150</div>";
      var pip150Marker = "<div class='noUi-marker noUi-marker-horizontal noUi-marker-large' style='left:75%;'></div>";
      document.getElementById("load-reduction-slider").getElementsByClassName("noUi-pips-horizontal")[0].innerHTML += ((pip50 + pip50Marker) + (pip100 + pip100Marker) + (pip150 + pip150Marker));

      // this is to make the pip markers clickable for setting 'load_reduction' value
      for(var el of document.getElementById("load-reduction-slider").getElementsByClassName('noUi-value')) {
        el.addEventListener('click', function(event) {
          self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: true });
          var slider = document.getElementById("load-reduction-slider");
          slider.noUiSlider.set(event.target.innerText);
          self.updateStore('load_reduction', event.target.innerText);

          setTimeout(function() {
            self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
          }, 800);
        })
      }

      // not ideal although if we use a watch function on `load_reduction`
      // the slider causes the value to change WHILE sliding - leading to a weird
      // UI experience - on mouseup is much cleaner
      document.getElementById("load-reduction-slider").getElementsByClassName("noUi-handle")[0].addEventListener('mouseup', function() {
        self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: true });
        if (self.run.site.load_profile.simulated_load_type == "annual") {
          self.updateAnnualKwhValueFromSlider();
        } else if (self.run.site.load_profile.simulated_load_type == "monthly") {
          self.updateMonthlyKwhValuesFromSlider();
        }

        setTimeout(function() {
          self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
        }, 800);
      })

      //TODO: the .click() should not ne necessary once bootstrap-vue integrated
      if(this.run.site.load_profile.typical_load_type == "simulated_building" && this.run.site.load_profile.simulated_load_type == "annual" && this.run.site.load_profile.annual_kwh) {
        document.querySelectorAll("[href='#simulated_building']")[0].click();
        this.getElecSimBuildingAnnualLoad();
      } else if(this.run.site.load_profile.typical_load_type == "simulated_building" && this.run.site.load_profile.simulated_load_type == "monthly") {
        document.querySelectorAll("[href='#simulated_building']")[0].click();
        this.getElecSimBuildingMonthlyLoad();
      } else if(this.run.site.load_profile.typical_load_type == "simulated_campus") {
        document.querySelectorAll("[href='#simulated_campus']")[0].click();
      } else if(this.run.site.load_profile.typical_load_type == "uploaded") {
        this.updateUploadedLoadProfile(JSON.parse(this.run.site.load_profile.loads_kw), 'electric');
        document.querySelectorAll("[href='#uploaded']")[0].click();
      }

      // for copied runs
      if(this.run.site.load_profile.typical_load_type == "simulated_building" && this.run.site.load_profile.simulated_load_type == "annual") {
        this.updateAnnualKwh();
      }
      
      if(this.run.site.load_profile.typical_load_file_data) {
        document.getElementById("run_site_attributes_load_profile_attributes_typical_load_file").value = this.run.site.load_profile.typical_load_file_data;

        var loadKw = JSON.parse(this.run.site.load_profile.loads_kw)
        var payload = {
          loadsKw: loadKw,
          minKw: Math.min.apply(Math, loadKw),
          meanKw: this.$store.getters.average(loadKw),
          maxKw: Math.max.apply(Math, loadKw),
        }

        this.$store.commit("updateObject", { object: "elecUploadedLoadProfile", value: payload });
      }
    }
  }
</script>

<style>
  .noUi-handle-lower {
    background-color: rgb(61,111,189);
    box-shadow: none;
  }
</style>
